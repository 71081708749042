<template>
    <img v-if="loaded"
         :src="img.src"
         @load="$emit('ready')"
         class="cache-img" />
    <div v-else
         class="cache-img__placeholder"
         ref="placeholder"></div>
</template>
<script>
import {loadResource} from '@/lib/DataLoader';

export default {
    name: 'CacheImage',
    props: {
        path: {
            default: null,
        },
    },
    data() {
        return {
            img: null,
            loaded: false,
        };
    },
    methods: {
        async loadImg(url) {
            this.img = new Image();
            this.img.src = url;
            this.img.onload = () => {
                this.loaded = true;
            };
        },
        isHidden(el) {
            return (el && el.offsetParent === null);
        },
    },
    watch: {
        async path() {
            if (!this.isHidden(this.$refs.placeholder)) {
                const resource = await loadResource(this.path);
                const url = URL.createObjectURL(resource);
                await this.loadImg(url);
            }
        },
    },
    async mounted() {
        const resource = await loadResource(this.path);
        const url = URL.createObjectURL(resource);
        await this.loadImg(url);
    },
};
</script>