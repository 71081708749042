<template>
    <div class="touchpoint-map-head">
        <a target="_blank"
           @click.prevent="handleLogoClick"
           href="#"
           class="touchpoint-map-head__logo-link">
            <div class="logo"></div>
        </a>

        <div class="touchpoint-map-head__title">
            {{ market.title }}
        </div>

        <div v-if="appIsCloseable"
             class="touchpoint-map-head__actions">
            <a target="_self"
               :href="logoLink"
               class="touchpoint-map-head__link">
                <action-button icon="times" />
            </a>
        </div>

        <LanguageSwitch v-if="languageSwitchEnabled" />
    </div>
</template>
<script>

import ActionButton from '@/components/ActionButton';
import CacheImg from '@/components/CacheImg';
import LanguageSwitch from '@/components/LanguageSwitch';

export default {
    name: 'TouchpointMapHead',
    components: {
        ActionButton,
        CacheImg,
        LanguageSwitch,
    },
    props: {
        market: {
            default: null,
        },
    },
    methods: {
        handleLogoClick() {
            if (process.env['VUE_APP_FAIR'] === 'true') {
                if (navigator.onLine) {
                    window.location.reload(true);
                }
                return;
            }
            if (process.env['VUE_APP_LOGO_EXTERNAL'] === 'true') {
                window.open(process.env['VUE_APP_LOGO_LINK'], '_blank');
            } else {
                this.$router.push('/');
            }
        },
    },
    computed: {
        languageSwitchEnabled() {
            return process.env['VUE_APP_LANGUAGE_SWITCHER'] !== 'false';
        },
        appIsCloseable() {
            return process.env['VUE_APP_CLOSEABLE'] === 'true';
        },
        logoLink() {
            return process.env['VUE_APP_LOGO_LINK'];
        },
    },
};
</script>