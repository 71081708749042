<template>
    <div class="paragraph-link-list">
        <h3 class="paragraph-link-list__title">{{ title }}</h3>
        <ul class="paragraph-link-list__links">
            <li v-for="item in paragraph.linklist"
                class="paragraph-link-list__item">
                <a :href="item.url"
                   :target="item.external ? '_blank' : '_self'"
                   class="paragraph-list-list__link">
                    {{ item.title }}
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
import ParagraphsMixin from '@/mixins/Paragraphs';

export default {
    name: 'ParagraphLinkList',
    mixins: [
        ParagraphsMixin,
    ],
};
</script>