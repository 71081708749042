<template>
    <component :is="is"
               :to="to"
               :target="touchpoint.type === 'external' ? '_blank' : '_self'"
               :href="touchpoint.type === 'external' ? touchpoint.link : null"
               class="touchpoint"
               :class="{
                   'touchpoint--small': small,
                   'touchpoint--fade-out': fadeOut,
                   'touchpoint--external': touchpoint.type === 'external',
                   'touchpoint--no-title': noTitle,
               }"
               :style="inlineStyle">
        <div class="touchpoint__icon">
            <span class="fa fa-info"></span>
        </div>
        <div class="touchpoint__name">
            {{ touchpoint.title }}
            <span v-if="touchpoint.type === 'external'"
                  class="fa fa-external-link"></span>
        </div>
    </component>
</template>
<script>
export default {
    name: 'Touchpoint',
    props: {
        fadeOut: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        touchpoint: {
            type: Object,
            default: [],
        },
        noLink: {
            type: Boolean,
            default: false,
        },
        noTitle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inlineStyle() {
            if (this.touchpoint.positions.top === null || this.touchpoint.positions.left === null) {
                return {top: `${Math.round(Math.random() * 100)}%`, left: `${Math.round(Math.random() * 100)}%`};
            } else {
                return {top: `${this.touchpoint.positions.top}%`, left: `${this.touchpoint.positions.left}%`};
            }
        },
        market() {
            return this?.$route?.params?.market;
        },
        locale() {
            return this?.$route?.params?.locale;
        },

        is() {
            if (this.noLink) {
                return 'div';
            } else if (this.touchpoint.type === 'external') {
                return 'a';
            } else {
                return 'router-link';
            }
        },
        to() {
            if (this.noLink || this.touchpoint.type === 'external') {
                return null;
            } else {
                return `/${this.locale}/${this.market}/touchpoint/${this.touchpoint.machine_name}`;
            }
        },
    },
};
</script>