<template>
    <template v-if="loading">
        Lädt...
    </template>
    <template v-else>
        <router-view />
    </template>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'App',
    data() {
        return {
            loading: true,
            timer: 0,
        };
    },
    methods: {
        ...mapActions(['bootstrap', 'setLocale']),

    },
    async mounted() {
        this.$nextTick(async () => {
            await this.bootstrap();
            this.loading = false;
        });
    },
};
</script>