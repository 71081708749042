<template>
    <template v-for="market in markets_overview">
        <router-link :to="`/${locale}/${market.machine_name}`">
            <div class="market-teaser">
                <img :src="market.image"
                     alt=""
                     width="400">
                <br>
                {{ market.title }}
                {{ market.machine_name }}
            </div>
        </router-link>
    </template>
</template>

<script>

import {mapActions, mapState} from 'vuex';

export default {
    name: 'ChooseMarket',
    methods: {
        ...mapActions(['setLocale']),
    },
    computed: {
        ...mapState(['markets_overview', 'active_locale']),
        locale() {
            return this.$route.params.locale;
        },
    },
};

</script>