import {createStore} from 'vuex';
import * as DataLoader from '@/lib/DataLoader';

export default createStore({
    state: {
        locales: [],
        active_locale: 'en',
        markets_overview: null,
        market_identifier: null,
        market: {},
    },
    mutations: {
        SET_MARKETS: (state, markets) => {
            state.markets_overview = markets;
        },
        SET_LOCALE: (state, active_locale) => {
            state.active_locale = active_locale;
        },
        SET_LOCALES: (state, locales) => {
            state.locales = locales;
        },
        SET_MARKET: (state, market) => {
            state.market = market;
        },
        SET_MARKET_IDENTIFIER: (state, identifier) => {
            state.market_identifier = identifier;
        },
    },
    actions: {
        // setter actions
        async setMarketIdentifier({commit, dispatch}, identifier) {
            await commit('SET_MARKET_IDENTIFIER', identifier);
        },
        async setLocale({state, commit, dispatch}, locale) {
            const availableLocales = state.locales.langs.map(l => l.shortcut);
            if (availableLocales.includes(locale)) {
                await commit('SET_LOCALE', locale);
            } else {
                await commit('SET_LOCALE', process.env['VUE_APP_FALLBACK_LANG']);
            }
        },
        async setLocales({commit, dispatch}, locales) {
            await commit('SET_LOCALES', locales);
        },
        // loading actions
        async bootstrap({state, commit}) {
            const data = await DataLoader.loadLocales(state.active_locale);
            await commit('SET_LOCALES', data);
        },
        async loadMarketsOverview({state, commit}) {
            const data = await DataLoader.loadMarketsOverview(state.active_locale);

            // download ALL images
            // (await DataLoader.getImagePaths(data)).forEach(path => {
            //     DataLoader.saveResource(path);
            // });

            await commit('SET_MARKETS', data);
        },
        async loadMarket({state, commit, dispatch}) {
            const data = await DataLoader.loadMarket(state.active_locale, state.market_identifier);

            // download ALL images
            // (await DataLoader.getImagePaths(data)).forEach(path => {
            //     DataLoader.saveResource(path);
            // });

            await commit('SET_MARKET', data);
        },
    },
    getters: {
        getTouchpointsByMachineName: state => machine_name => {
            if (state.market.routes) {
                return state.market.routes.filter((tp) => tp.machine_name === machine_name)[0];
            } else {
                return [];
            }
        },
        getTouchpointsById: state => id => {
            if (state.market.routes) {
                return state.market.routes.filter((tp) => tp.id === id)[0];
            } else {
                return [];
            }
        },
    },
    modules: {},
});
